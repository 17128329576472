<template>
  <div>
    <Edit
      :tarefa="currentTarefa"
      :originalTarefa="originalTarefa"
      :update="updateTarefa"
      :truncate="limpaTabelaTarefa"
      :close="closeEdit"
      :crons="crons"
      :descricao="descSingular"
      :modos="modos"
    />
    <Info
      :tarefa="currentTarefa"
      :close="closeInfo"
      :descricao="descSingular"
      :loadTarefas="loadTarefas"
      :syncTarefa="syncTarefa"
    />
    <Loading :isActive="loading"></Loading>
    <div class="filters row" style="margin-top:0">
      <div class="col-1-2">
        <span class="filters__search">
          <input
                  type="text"
                  v-model="termo"
                  placeholder="Filtrar cargas por ..."
          >
          <img
                  style="position: relative; right: -10px;"
                  src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
          >
        </span>
      </div>
      <div class="col-1-2">
        <div class="pull-right">
          <button class="button button-primary" v-on:click="loadTarefas">Atualizar</button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Tarefa</th>
          <th>Modo</th>
          <th>Status</th>
          <th>SQL</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(tarefa, i) in listaTarefas" v-bind:key="i" >
          <td>
            {{ tarefa.tarefa }}
            <small>{{ tarefa.descricao }} | {{ tarefa.cron | humanCron(crons) }}</small>
          </td>
          <td>{{ tarefa.modo | humanNcr(modos) }}</td>
          <td>
            <span class="label success" v-if="+tarefa.ativo === 1">Ativo</span>
            <span class="label error" v-else>Inativo</span>
          </td>
          <td>
            <router-link v-if="hasSqlErp(tarefa)" :to="`/configuracoes/sql-erp-integracao?q=${tarefa.tarefa}`">
              Ver SQL
            </router-link>
          </td>
          <td class="actions col-4 col-md-3 ">
            <CButton color="primary" size="sm" v-on:click="openEdit(tarefa)">
              <fa-icon icon="pencil-alt" />
            </CButton>
            <CButton color="success" size="sm" @click="syncTarefa(tarefa)" title="Sincronizar agora">
              <fa-icon icon="sync"/>
            </CButton>
            <CButton v-bind:color="tarefa | tipoButtonTarefa(tarefa)" size="sm" v-on:click="openInfo(tarefa)" v-bind:title="tarefa | titleTarefa">
              <fa-icon icon="info-circle"/>
            </CButton>
            <LogAlteracaoDados
              tabela="tarefa"
              :chave="tarefa.id"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put } from '@/helpers/apiRequest';
import Edit from './Edit';
import Info from './Info';
// import Validate from './Validate';
import { normalizeString, arrayFilter } from '@/helpers/utils';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

export default {
  name: 'tarefas',
  components: {
    // Validate,
    Edit,
    Info,
  },
  data() {
    return {
      termo: '',
      loading: false,
      currentTarefa: {},
      originalTarefa: {},
      lastValidation: '',
      tarefas: [],
      descPlural: 'Tarefa',
      descSingular: 'Tarefas',
      crons: {
        '* * * * *': 'A cada minuto',
        '*/2 * * * *': 'A cada 2 minutos',
        '*/3 * * * *': 'A cada 3 minutos',
        '*/4 * * * *': 'A cada 4 minutos',
        '*/5 * * * *': 'A cada 5 minutos',
        '*/6 * * * *': 'A cada 6 minutos',
        '*/7 * * * *': 'A cada 7 minutos',
        '*/8 * * * *': 'A cada 8 minutos',
        '*/9 * * * *': 'A cada 9 minutos',
        '*/10 * * * *': 'A cada 10 minutos',
        '*/15 * * * *': 'A cada 15 minutos',
        '*/20 * * * *': 'A cada 20 minutos',
        '*/30 * * * *': 'A cada 30 minutos',
        '0 */1 * * *': 'A cada 1 hora',
        '0 */2 * * *': 'A cada 2 horas',
        '0 */3 * * *': 'A cada 3 horas',
        '0 */4 * * *': 'A cada 4 horas',
        '0 */6 * * *': 'A cada 6 horas',
        '0 */8 * * *': 'A cada 8 horas',
        '0 */12 * * *': 'A cada 12 horas',
        '0 0 * * *': 'Pontualmente à 0h',
        '0 1 * * *': 'Pontualmente à 1h',
        '0 2 * * *': 'Pontualmente às 2h',
        '0 3 * * *': 'Pontualmente às 3h',
        '0 4 * * *': 'Pontualmente às 4h',
        '0 5 * * *': 'Pontualmente às 5h',
        '0 6 * * *': 'Pontualmente às 6h',
        '0 7 * * *': 'Pontualmente às 7h',
        '0 8 * * *': 'Pontualmente às 8h',
        '0 9 * * *': 'Pontualmente às 9h',
        '0 10 * * *': 'Pontualmente às 10h',
        '0 11 * * *': 'Pontualmente às 11h',
        '0 12 * * *': 'Pontualmente às 12h',
        '0 13 * * *': 'Pontualmente às 13h',
        '0 14 * * *': 'Pontualmente às 14h',
        '0 15 * * *': 'Pontualmente às 15h',
        '0 16 * * *': 'Pontualmente às 16h',
        '0 17 * * *': 'Pontualmente às 17h',
        '0 18 * * *': 'Pontualmente às 18h',
        '0 19 * * *': 'Pontualmente às 19h',
        '0 20 * * *': 'Pontualmente às 20h',
        '0 21 * * *': 'Pontualmente às 21h',
        '0 22 * * *': 'Pontualmente às 22h',
        '0 23 * * *': 'Pontualmente às 23h',
        '0 4,16 * * *': '2x do dia (4h e 16h)',
        '0 5,17 * * *': '2x do dia (5h e 17h)',
      },
      modos: {
        'NCR' : {
          'alias' : 'NCR',
          'label' : 'NCR (Padrão)',
          'obs'   : '(NCR = Número de Controle de Registro) Formato padrão de sincronização padrão. Este formato obtém todos os' +
            ' registros do banco de dados do sistema de gestão e insere/atualiza os registros no banco de dados local.' +
            ' Os registros atualizados são "incrementados" e controlados por uma segunda tabela, que define ' +
            '  quais registros estão ativos',
          'usaManutencao' : true,
          'usaModoExecucao' : true,
          'usaEliminaRegistros' : true,
          'usaMargemMinimaRegistros' : true,
        },
        'NCR_LOTE' : {
          'alias' : 'LOTE',
          'label' : 'NCR EM LOTE (Recomendado para tabelas/views grandes)',
          'obs'   : '(NCR = Número de Controle de Registro). Este formato obtém todos os' +
            ' registros do banco de dados do sistema de gestão em partes, utilizando consultas por LIMIT e OFFSET' +
            ' (dependendo do banco de dados pode utilizar consultas por ROWNUM), e insere/atualiza os registros no banco' +
            ' de dados local. Os registros atualizados são "incrementados" e controlados por uma segunda tabela,' +
            ' que define quais registros estão ativos',
          'usaManutencao' : true,
          'usaModoExecucao' : true,
          'usaEliminaRegistros' : true,
          'usaMargemMinimaRegistros' : false,
        },
        'NCR_LOTE_INDEX' : {
          'alias' : 'LOTE INDEXADO',
          'label' : 'NCR EM LOTE INDEXADO (Recomendado para tabelas/views grandes com coluna de indice)',
          'obs'   : '(NCR = Número de Controle de Registro). Este formato obtém todos os' +
           ' registros do banco de dados do sistema de gestão em partes, utilizando uma coluna de indice' +
           ' para obter essas partes, e insere/atualiza os registros no banco' +
           ' de dados local. Os registros atualizados são "incrementados" e controlados por uma segunda tabela,' +
           ' que define quais registros estão ativos',
          'usaManutencao' : true,
          'usaModoExecucao' : true,
          'usaEliminaRegistros' : false,
          'usaMargemMinimaRegistros' : false,
        },
        'COMPARATOR' : {
          'alias' : 'COMPARADOR',
          'label' : 'COMPARADOR (Recomendado para tabelas/views pequenas/médias)',
          'obs'   : 'Este formato obtém todos os registros do banco de dados do sistema de gestão e todos os registros' +
            ' do banco de dados local, após isso, faz a comparação dos dados entre as duas plataformas.' +
            ' Registros diferentes é resultam em UPDATE no banco local, registros que faltam localmente é feito o INSERT,' +
            ' e registros que não vierem do banco de gestão é feito o DELETE no local',
          'usaManutencao' : true,
          'usaModoExecucao' : true,
          'usaEliminaRegistros' : true,
          'usaMargemMinimaRegistros' : true,
        },
        'HASH_COMPARATOR' : {
          'alias' : 'HASH_COMPARATOR',
          'label' : 'HASH_COMPARATOR (Recomendado para tabelas/views pequenas/médias)',
          'obs'   : 'Para utilizar a carga neste modo, é imprescindível que o valor da coluna ID seja ÚNICO. Caso contrário, a carga estará sujeita a problemas de sincronização. Este formato obtém todos os registros do banco de dados do sistema de gestão e todos os registros' +
            ' do banco de dados local, após isso, faz a comparação da coluna de HASH entre o local e o remoto.' +
            ' Registros diferentes resultam em UPDATE no banco local, registros que faltam localmente é feito o INSERT,' +
            ' e registros que não vierem do banco de gestão é feito o DELETE no local',
          'usaManutencao' : true,
          'usaModoExecucao' : true,
          'usaEliminaRegistros' : true,
          'usaMargemMinimaRegistros' : true,
        },
        'REFRESH_VIEW' : {
          'alias' : 'REFRESH_VIEW',
          'label' : 'ATUALIZAÇÃO VIEW (Recomendado apenas para views locais materializadas)',
          'obs'   : 'Este formato roda um processo localmente para efetuar a "materialização" dos registros em uma' +
           ' segunda view no banco de dados local, geralmente para aumentar a performance de consultas grandes',
          'usaManutencao' : false,
          'usaModoExecucao' : false,
          'usaEliminaRegistros' : false,
          'usaMargemMinimaRegistros' : false,
        },
        'MONGO_CACHE' : {
          'alias' : 'CACHE NOSQL',
          'label' : 'CACHE NOSQL (Recomendado apenas para tarefas locais)',
          'obs'   : 'Este formato roda um processo de cacheamento no banco de dados do mongoDB.' +
            ' Nem todas as views e processos podem ser configurados para este modo, pois exije' +
            ' que programação seja efetuada para tal configuração.',
          'usaManutencao' : false,
          'usaModoExecucao' : false,
          'usaEliminaRegistros' : false,
          'usaMargemMinimaRegistros' : false,
        },
        'JOB' : {
          'alias' : 'JOB/SCRIPT',
          'label' : 'JOB (Executar script ou tarefa específica)',
          'obs'   : 'Este não é um processo de sincronização, mas um processo pré-definido e programado para executar' +
            ' um conjunto de ações ou um script pré-definido. Nem todos os processo podem ser configurados para' +
            ' este formato, pois ele exije programação.',
          'usaManutencao' : false,
          'usaModoExecucao' : false,
          'usaEliminaRegistros' : false,
          'usaMargemMinimaRegistros' : true,
        },
        'INCREMENTAL' : {
          'alias' : 'INCREMENTAL',
          'label' : 'INCREMENTAL (Sincroniza apenas registros que sofreram atualização no ERP)',
          'obs'   : 'Este formato obtém todos os registros que sofreram alterações desde sua última execução.' +
            ' Para rodar este tipo de consulta, é necessário que a view/tabela possua um campo chamado "dataatualizacao"' +
            ' que contenha a data e hora da última atualização do registro, após a sincronização, o sistema guarda' +
            ' a maior data/hora dentre os registros para utilizar na próxima sincronização. Obs: Para invalidar' +
            ' registros utilizando este formato, é necessário que o registro, mesmo que inativo, esteja disponível' +
            ' na view com o o campo "ativo" igual a "0". Para ativação deste formato, é necessário a criação de triggers' +
            'em cima das tabelas envolvidas no processo',
          'usaManutencao' : true,
          'usaModoExecucao' : true,
          'usaEliminaRegistros' : false,
          'usaMargemMinimaRegistros' : false,
        },
        'PHP_SCRIPT' : {
          'alias' : 'PHP/SCRIPT',
          'label' : 'PHP (Executar script em PHP)',
          'obs'   : 'Este não é um processo de sincronização, mas um processo pré-definido e programado para executar' +
              ' um script em PHP. Nem todos os processo podem ser configurados para este formato, pois ele' +
              ' exije programação, em casos de dúvidas ou necessidade no uso, entrar em contato' +
              ' com um consultor comercial.',
          'usaManutencao' : false,
          'usaModoExecucao' : false,
          'usaEliminaRegistros' : false,
          'usaMargemMinimaRegistros' : false,
          'tipoScript' : 'text/x-php'
        },
        'SQL_SCRIPT' : {
          'alias' : 'SQL/SCRIPT',
          'label' : 'SQL (Executar script em SQL)',
          'obs'   : 'Este não é um processo de sincronização, mas um processo pré-definido e programado para executar' +
              ' um script em SQL. Nem todos os processo podem ser configurados para este formato, pois ele' +
              ' exije programação, em casos de dúvidas ou necessidade no uso, entrar em contato' +
              ' com um consultor comercial.',
          'usaManutencao' : false,
          'usaModoExecucao' : false,
          'usaEliminaRegistros' : false,
          'usaMargemMinimaRegistros' : false,
          'usaBancoExecucao' : true,
          'tipoScript' : 'text/x-sql'
        },
      }
    };
  },
  computed: {
    listaTarefas() {
      return arrayFilter(this.tarefas, this.termo, ['descricao', 'tarefa', 'modo'])
    },
  },
  filters: {
    humanCron(cron, crons) {
      return crons[cron] || cron;
    },
    humanNcr(modo, modos) {
      return modos[modo]?.alias || modo;
    },
    tipoButtonTarefa(tarefa) {
      const { ultimaSincronizacao } = tarefa;

      if (ultimaSincronizacao &&
          ultimaSincronizacao.id &&
          !ultimaSincronizacao.sucesso) {
        return 'danger';
      }

      if (ultimaSincronizacao && ultimaSincronizacao.sucesso) {
        return 'info';
      }

      return 'secondary';
    },

    titleTarefa(tarefa) {
      const { ultimaSincronizacao } = tarefa;

      if (ultimaSincronizacao && ultimaSincronizacao.msg) {
        return ultimaSincronizacao.msg;
      }

      return 'Nunca sincronizada';
    },
  },
  watch: {
    "termo": function(newValue) {
      history.pushState({}, null, this.$route.path + '?q=' + (newValue || ''));
    },
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_CARGA_DADOS'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    openEdit(tarefa) {
      this.currentTarefa = clone(tarefa);
      this.originalTarefa = {'ativo': tarefa.ativo}
      this.$modal.show('tarefaEdit');
    },
    closeEdit() {
      this.$modal.hide('tarefaEdit');
    },
    openInfo(tarefa) {
      this.currentTarefa = tarefa;
      this.$modal.show('tarefaInfo');
    },
    closeInfo() {
      this.$modal.hide('tarefaInfo');
    },
    openValidate() {
      this.$modal.show('tarefaValidate');
    },
    closeValidate() {
      this.$modal.hide('tarefaValidate');
    },
    loadTarefas() {
      this.loading = true;
      get('/admin/tarefas')
        .then((json) => (this.tarefas = json))
        .catch(() => (this.tarefas = []))
        .then(() => (this.loading = false))
        .catch(() => (this.loading = false))
        .then(this.changeEditingTask);
    },
    changeEditingTask() {
      if (this.currentTarefa) {
        let tarefa = _find(this.tarefas, { id: this.currentTarefa.id });
        if (tarefa) {
          this.currentTarefa = clone(tarefa);
        }
      }
    },
    updateTarefa() {
      const { id } = this.currentTarefa;
      this.loading = true;
      let request = put(`/admin/tarefas/${id}`, this.currentTarefa);
      request.then(() => this.loadTarefas());
      request.catch(() => (this.loading = false));
      return request;
    },
    limpaTabelaTarefa() {
      const { id, tabela } = this.currentTarefa;

      this.$swal({
        title: 'Confirma Limpar tabela ?',
        text: 'Essa ação irá excluir todos os registros da tabela "'+ tabela +'"',
        showCancelButton: true,
        icon: 'warning',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            post(`/admin/tarefas/truncate/${id}`, this.currentTarefa)
              .then((json) => {
                  this.loading = false;
                  this.$swal({
                    title: 'Sucesso!',
                    text: json.message,
                    icon: 'success',
                    confirmButtonText: 'Fechar',
                  });
                })
                .catch((error) => {
                  this.loading = false;
                  this.$swal({
                    title: error.message,
                    text: 'Desative e atualize a carga para continuar',
                    icon: 'warning',
                    confirmButtonText: 'Fechar',
                  });
                })
          }
        })
        .catch(() => {
        });
    },
    syncTarefa(tarefa) {
      const { id } = tarefa;
      this.loading = true;
      post(`/admin/tarefas/sync/${id}`)
        .then((json) => {
          this.loading = false;
          this.$swal({
            title: 'Sucesso!',
            text: json.message,
            icon: 'success',
            confirmButtonText: 'Fechar',
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: error.message,
            text: 'Tente novamente em alguns instantes',
            icon: 'warning',
            confirmButtonText: 'Fechar',
          });
        });
    },
    validateViews() {
      this.loading = true;
      get(`/admin/tarefas/validate`)
        .then((json) => {
          this.openValidate();
          this.loading = false;
          this.lastValidation = json.message;
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: error.message,
            text: 'Tente novamente em alguns instantes',
            icon: 'warning',
            confirmButtonText: 'Fechar',
          });
        });
    },
    hasSqlErp(tarefa) {
      return !_isEmpty(tarefa.sqlErp);
    }
  },
  beforeMount() {
    this.termo = this.$route.query.q || '';
    this.loadTarefas();
    this.loadDescricaoModulo();
  },
};
</script>
