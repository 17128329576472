<template>
  <div>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Cargas">
        <Carga url="/admin/cargas/carga"/>
      </CTab>
      <CTab title="Utilitários">
        <Utilitarios url="/admin/cargas/utilitarios"/>
      </CTab>
      <CTab title="Rotinas">
        <Rotina url="/admin/rotinas"/>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import Carga from './Carga';
import { loadDescs } from '@/helpers/utils';
import Utilitarios from './Utilitarios';
import Rotina from './Rotina/Rotina';
export default {
  name: 'cargas',
  components: {
    Carga,
    Utilitarios,
    Rotina,
  },
  data() {
    return {
      descSingular: '',
      descPlural: '',
    };
  },
  methods: {

  },

  beforeMount() {
    const { descSingular, descPlural } = loadDescs('ADMIN_CARGA_DADOS');
    this.descSingular = descSingular;
    this.descPlural = descPlural;
  },
};
</script>
