<template>
  <modal name="tarefaInfo" height="auto" width="1024" :scrollable="true" :clickToClose="true">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Última sincronização">
            <UltimaCarga :tarefa="tarefa" />
          </CTab>
          <CTab title="Histórico de sincronizações">
            <LogUltimasCargas :tarefa="tarefa" />
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button class="button" v-on:click="close">Fechar</button>
      <button class="button button-success" v-on:click="syncTarefa(tarefa)">Sincronizar</button>
      <button class="button button-primary" v-on:click="loadTarefas">Atualizar</button>
    </div>
  </modal>
</template>
<script>
import CloseModal from "../../components/CloseModal";
import UltimaCarga from './CargaInfo/UltimaCarga';
import LogUltimasCargas from './CargaInfo/LogUltimasCargas';

export default {
  name: "tarefaInfo",
  props: [
    "close",
    "tarefa",
    "descricao",
    "loadTarefas",
    "syncTarefa"
  ],
  components: {
    CloseModal,
    UltimaCarga,
    LogUltimasCargas
  }
};
</script>
