<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Utilitário</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Limpar cache
            <small>Limpa o cache da aplicação</small>
          </td>
          <td class="actions">
            <button
              type="button"
              v-on:click="cacheConfirm()"
              class="button button-primary">Executar
            </button>
          </td>
        </tr>
        <tr>
          <td>Deslogar usuários
            <small>Desloga todos os usuários</small>
          </td>
          <td class="actions">
            <button
              type="button"
              v-on:click="logoutConfirm()"
              class="button button-primary">Executar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {post} from "@/helpers/apiRequest";

export default {
  name: "utilitarios",
  props: [],
  methods: {
    logoutConfirm() {
      this.$swal({
        title: "Confirma esta ação?",
        text: "Ao confirmar, todos usuários vão ser deslogados, inclusive você.",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancelar",
      })
        .then((res) => {
            if (res.value) {
              this.loading = false;
              post(`/admin/utilitarios/logout`)
                .then((json) => {
                  this.loading = false;
                  this.$swal({
                    title: "Sucesso!",
                    text: json.message,
                    icon: "success",
                    confirmButtonText: "Fechar",
                  })
                  .then((res) => {
                    if (res.value) {
                      this.logout();
                    }
                  })
                })
                .catch((err) => {
                  this.loading = true;
                  this.$swal({
                    title: "Falha!",
                    text: err.message,
                    icon: "error",
                  });
                });
            }
          }
        )
        .catch(() => {
        });
    },
    cacheConfirm() {
      this.$swal({
        title: "Confirma esta ação?",
        text: "Ao confirmar, o cache da aplicação será limpo.",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancelar",
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            post(`/admin/utilitarios/clear-cache`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: "Sucesso!",
                  text: json.message,
                  icon: "success",
                  confirmButtonText: "Fechar",
                })
              })
              .catch((err) => {
                this.loading = true;
                this.$swal({
                  title: "Falha!",
                  text: err.message,
                  icon: "error",
                });
              });
          }
        })
        .catch(() => {});
    },
    logout() {
      localStorage.clear()
      this.$router.push('/login')
    }
  },
};
</script>
