<template>
  <modal name="rotinaExecutar" height="auto" width="600" :scrollable="true" :clickToClose="true">
    <CloseModal :close="close"/>
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="rotina.titulo">
            <div class="form">
              <div class="form-single">
                <div class="xrow" v-show="rotina.observacao">
                  <div class="alert alterar">
                    <small>{{rotina.observacao}}</small>
                  </div>
                  </div>
                <div class="xrow" v-for="parametro in rotina.parametros">
                  <div class="col-1-1" v-if="parametro.tipo === 'ARQUIVO_EXCEL'">
                    <div class="form-group">
                      <div>
                        <label>{{parametro.label}}:</label>
                      </div>
                      <div class="col-1-1 dropzone-custom-content">
                        <label>
                          <p>Clique para selecionar um arquivo do seu computador</p>
                          <input type="file" name="image" style="display: none;" @change="attachFileToRotina($event, parametro)" />
                          <small v-show="files.length > 0">Arquivo importado com sucesso! Clique em executar para concluir a operação</small>
                          <small v-show="files.length === 0">Nenhum arquivo selecionado.</small>
                        </label>
                      </div>
                      <label class="message" style="color:red" v-if="parametro.obrigatorio"> Obrigatório </label>
                      <label class="message" style="color:green" v-else> Opcional </label>
                    </div>
                  </div>
                  <br/>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button class="button" v-on:click="onClose">Fechar</button>
      <button class="button button-success" v-on:click="onExecutarRotina(rotina)" :disabled="!enableExecute">Executar</button>
    </div>
  </modal>
</template>

<style scoped>
.dropzone-custom-content {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
}

.dropzone-custom-content p {
  font-weight: bold;
  cursor: pointer;
}

.dropzone-custom-content label {
  background-color: #e6e6e6;
  border-radius: 3px;
  font-weight: normal;
  padding: 5px;
}
</style>

<script>
import CloseModal from "../../../components/CloseModal";

export default {
  name: "rotinaExecutar",
  props: ["rotina", "close", "executarRotina"],
  components: {
    CloseModal,
  },
  data() {
    return {
      files: [],
      enableExecute: false
    }
  },
  methods: {
    attachFileToRotina(event, parametro) {
      const name = parametro.parametro;
      const file = event.target.files[0];

      const index = this.files.map(e => e.name).indexOf(name);
      if (index > -1)  {
        this.files.splice(index, 1);
      }

      this.files.push({name, file});

      this.execEnableExecute();
    },
    onExecutarRotina(rotina) {
      this.executarRotina(rotina, true, this.files);
      this.files = [];
    },
    onClose() {
      this.close();
      this.files = [];
    },
    execEnableExecute() {
      this.enableExecute = false;

      const parametrosObrigatorios = [];
      
      this.rotina.parametros.map(i => {
        if (i['obrigatorio']) {
          parametrosObrigatorios.push(i['parametro']);
        }
      });
      
      const filesPluckName         = this.files.map(i => i['name']);
      
      let difference = parametrosObrigatorios.filter(x => !filesPluckName.includes(x));
      
      if (parametrosObrigatorios.length == 0 || difference.length == 0) {
        this.enableExecute = true;
      }
    }
  },
  watch: {
    "rotina": function() {
      if (this.rotina.parametros) {
        this.execEnableExecute()
      }
    },
  },
};
</script>
