<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="">
            Última Sincronização
            {{ tarefa ? tarefa.tarefa : '' }}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-hover" style="margin-left: auto; margin-right: auto;">
            <thead>
            <tr>
              <th>Chave</th>
              <th>Valor</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(valor, chave) in tarefa.ultimaSincronizacao">
              <td>{{ chave }}</td>
              <td style="word-wrap: break-word;">{{ valor }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UltimaCarga",
  props:[
    "tarefa"
  ]
}
</script>

<style scoped>

</style>