<template>
  <div v-if="!!tarefa">
    <div v-if="isFetching" class="">
      <LoadingMessage message="Carregando, aguarde" is-active align-center />
    </div>
    <div v-else-if="errorMessage" class="alert alert-danger">
      <pre>{{ errorMessage }}</pre>
    </div>
    <table-paginated
      v-else
      :itens="result.itens"
      :columns="columns"
      :paginator="result.paginator"
      :onPageChange="getLogs"
    />
  </div>
</template>

<script>
import {get} from "@/helpers/apiRequest";
import TablePaginated from "@/components/crud/TablePaginated";
import {toDateTime} from "@/helpers/utils";

export default {
  name: "LogUltimasCargas",
  props:[
    "tarefa"
  ],
  components: {
    TablePaginated,
  },
  data() {
    return {
      errorMessage: null,
      isFetching: false,
      result : {
        itens: [],
        paginator: {},
        meta: {}
      },
      columns: [
        {label: 'Sucesso', field: 'sucesso', sortable: false, representedAs: (row) => row.sucesso ? 'Sim' : 'Não' },
        {label: 'Mensagem', field: 'mensagem_retorno', sortable: false },
        {label: 'Tempo', field: 'tempo_execucao', sortable: false, representedAs: (row) => `${row.tempo_execucao} seg` },
        {label: 'Início', field: 'dthora_inicio', sortable: false, representedAs: (row) => toDateTime(row.dthora_inicio) },
        {label: 'Fim', field: 'dthora_fim', sortable: false, representedAs: (row) => toDateTime(row.dthora_fim) },
      ],
    }
  },
  methods: {
    getLogs(page = 1) {
      this.isFetching = true;
      this.errorMessage = null;

      get(`/admin/tarefas/log-execucao/${this.tarefa.tarefa}?page=${page}`)
        .then((response) => this.result = response)
        .catch((error) => this.errorMessage = error.message)
        .finally(() => {
          this.isFetching = false;
        })
    },
  },
  mounted() {
    this.getLogs(1);
  },
}
</script>

<style scoped>

</style>